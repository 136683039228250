import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useWindowSize } from 'react-use'
import { ProductSlider } from '../components/shared/sliders'

import {
    SEO,
    RichText,
    TagsBlock,
    Social,
    FeaturedItemBlock,
    Space,
    LinkType,
} from '../components/shared'

import {
    desktopVw,
    mobileVw,
    desktopBreakpoint,
    colors,
    letterSpacing,
    fonts,
} from '../styles'

import {
    IngredientsList,
    RelatedProducts,
    Preparations,
    RecipeHero,
    ImageBlock,
} from '../components/recipe'

import { ProductTipSection } from '../components/product'
import { useWishlistRecipes, useLayout, useLang, useLocale, useDictionaryQuery } from '../hooks'
import { Heart, HeartFilled } from '../components/shared/icons'

const Recipe = ({
    location,
    data: { contentfulPageRecipe, contentfulGlobalSite },
    pageContext: { shopenful },
}) => {
    const {
        seoTitle,
        seoDescription,
        title,
        images,
        description,
        numberOfServings,
        preparationTime,
        ingredients,
        relatedProducts,
        allergyLabels,
        difficultyLevel,
        preparation,
        inspirationTitle,
        inspirationText,
        seasonText,
        id,
        thumbnailImage,
        slug,
        node_locale,
    } = contentfulPageRecipe

    const {
        recipePreparationTitle,
        recipeIngredientsTitle,
        recipeRelatedProductsTitle,
        decorationTipSectionRecipePage,
        decorationRecipePageBottomMobile,
        decorationRecipePageBottomDesktop,
        breadcrumbsRecipePages,
        instagramLink,
        instagramIcon,
        facebookLink,
        recipePageImageBlock1,
        recipePageImageBlock2,
        recipeInstagramText,
        recipeInstagramTitle,
        classicProductPageTestimonialDecoration,
    } = contentfulGlobalSite

    const locale = useLocale();
    const { recipeDiscoverProducts, recipeFeatureItemBlockTitle } = useDictionaryQuery()

    const [onWishlist, setOnWishlist] = useState(false)
    const { width: windowWidth } = useWindowSize()
    const { wishlistRecipes } = useLayout()
    const setWishlistRecipes = useWishlistRecipes()
    useEffect(() => {
        const currentWishlistRecipes =
            typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
        if (currentWishlistRecipes) {
            const localWishlist = JSON.parse(currentWishlistRecipes)
            if (slug in localWishlist) {
                setOnWishlist(true)
            } else {
                setOnWishlist(false)
            }
        }
    }, [wishlistRecipes])
    const handleAddToWishlist = () => {
        const recipe = {
            slug,
            thumbnailImage,
            title,
        }
        const currentWishlistRecipes =
            typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
        if (currentWishlistRecipes) {
            const localWishlist = JSON.parse(currentWishlistRecipes)
            if (slug in localWishlist) {
            } else {
                localWishlist[recipe.slug] = { ...recipe }
                localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
                setWishlistRecipes(localWishlist)
            }
        } else {
            const localWishlist = {}
            localWishlist[recipe.slug] = { ...recipe }
            localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
            setWishlistRecipes(localWishlist)
        }
    }

    const lang = useLang();

    const handleRemoveFromWishlist = () => {
        const recipe = {
            slug,
            thumbnailImage,
            title,
        }
        const currentWishlistRecipes =
            typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
        const localWishlist = JSON.parse(currentWishlistRecipes)
        if (slug in localWishlist) {
            delete localWishlist[recipe.slug]
            localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
            setWishlistRecipes(localWishlist)
        }
    }

    return (
        <StyledRecipe>
            <SEO
                title={seoTitle}
                location={location}
                description={seoDescription}
                pageType='Recipe'
            />
            <div>
                <Breadcrumbs className='breadcrumbs-wrapper'>
                    {breadcrumbsRecipePages.map(({ link, title }, i) => (
                        <React.Fragment key={i}>
                            <LinkType
                                to={`/${lang}${link.slug}`}
                                alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                            >
                                {title}
                            </LinkType>
                            {' > '}
                        </React.Fragment>
                    ))}
                    <span style={{ color: `${colors.mailleGold}` }}>{title}</span>
                </Breadcrumbs>
                <Header>
                    <Hero aria-label='Recipe hero'>
                        <RecipeHero images={images}>
                            {
                                locale === 'fr' &&
                                <AddToWishlist
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!onWishlist) {
                                            handleAddToWishlist()
                                            setOnWishlist(true)
                                        } else {
                                            handleRemoveFromWishlist()
                                            setOnWishlist(false)
                                        }
                                    }}
                                >
                                    {onWishlist ? <StyledHeartFilled /> : <StyledHeart />}
                                </AddToWishlist>
                            }
                        </RecipeHero>
                    </Hero>
                    <Container>
                        <TextWrapper>
                            <Title>{title}</Title>
                            <Description>
                                <RichText json={description} />
                            </Description>
                        </TextWrapper>
                        <TagsBlock
                            servings={numberOfServings}
                            prepTime={preparationTime}
                            tags={allergyLabels}
                            difficulty={difficultyLevel}
                        />
                    </Container>
                </Header>
            </div>
            <TopDecorationWrapper>
                <ProductTipSection
                    pageType="Recipe"
                    decoration={decorationTipSectionRecipePage}
                    title={inspirationTitle}
                    description={inspirationText}
                />
            </TopDecorationWrapper>
            <Wrapper>
                <Main aria-label='Recipe content'>
                    <RecipeInstructions france={locale === 'fr'}>
                        <IngredientsList
                            title={recipeIngredientsTitle}
                            ingredients={ingredients}
                        />
                        {locale === 'fr' && windowWidth < 1024 && (
                            <MobileWrapper className='mobileOnly'>
                                <ProductSlider
                                    title={recipeDiscoverProducts}
                                    products={relatedProducts}
                                    shopenful={shopenful}
                                />
                            </MobileWrapper>
                        )}
                        <Preparations
                            preparation={preparation}
                            title={recipePreparationTitle}
                        />
                    </RecipeInstructions>
                    {locale === 'fr' && windowWidth >= 1024 && (
                        <RelatedProductsSidebar>
                            <Space l={3} s={1} />
                            <RelatedProducts
                                title={recipeRelatedProductsTitle}
                                relatedProducts={relatedProducts}
                            />
                        </RelatedProductsSidebar>
                    )}
                </Main>
                <Space l={1} s={1} />
                {
                    recipeInstagramTitle &&
                    <>
                        <SocialWrapper>
                            <Social
                                social={{
                                    title: 'Partagez Cette Recette',
                                    share: ['Instagram', 'Facebook'],
                                    links: [`${instagramLink}`, `${facebookLink}`],
                                }}
                            />
                        </SocialWrapper>
                        <Space l={2} s={1} />
                        <BlockWrapper
                            href={instagramLink}
                            alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
                        >
                            <ImageBlock data={recipePageImageBlock1} reverse />
                            <InstagramBlockWrapper>
                                <Image
                                    style={{
                                        position: 'absolute',
                                        zIndex: '-1',
                                        height: `${desktopVw(250)}`,
                                        width: '50%',
                                    }}
                                    className='desktopOnly'
                                    fluid={classicProductPageTestimonialDecoration[0].image.fluid}
                                    alt={classicProductPageTestimonialDecoration[0].image.description}
                                />
                                <InstagramIcon
                                    fluid={instagramIcon.fluid}
                                    alt={instagramIcon.title}
                                />
                                <InstagramTitleWrapper>
                                    {recipeInstagramTitle
                                        .concat(` ${recipeInstagramText}`)
                                        .split('#')
                                        .map((word, i) => (
                                            <span key={i}>
                                                {i === 1 ? (
                                                    <span style={{ fontFamily: `${fonts.mrs}` }}>#</span>
                                                ) : null}
                                                {word}
                                            </span>
                                        ))}
                                </InstagramTitleWrapper>
                            </InstagramBlockWrapper>
                            <ImageBlock data={recipePageImageBlock2} />
                        </BlockWrapper>
                    </>
                }
                <Space l={3} s={4} />
                {/* {console.log("seasonText", seasonText)} */}
                {
                    seasonText &&
                    <StyledFeaturedItemBlock>
                        <FeaturedItemBlock
                            title={[recipeFeatureItemBlockTitle]}
                            id={id}
                            season={seasonText}
                            hideRating
                        />
                    </StyledFeaturedItemBlock>

                }
                <BottomDecorationWrapper>
                    <div className='mobileOnly'>
                        <Image
                            fluid={decorationRecipePageBottomMobile.image.fluid}
                            alt={decorationRecipePageBottomMobile.image.title}
                        />
                    </div>
                    <div
                        className='desktopOnly'
                        style={{
                            width: `${desktopVw(900)}`,
                            marginLeft: `${desktopVw(450)}`,
                        }}
                    >
                        <Image
                            fluid={decorationRecipePageBottomDesktop.image.fluid}
                            alt={decorationRecipePageBottomDesktop.image.title}
                        />
                    </div>
                </BottomDecorationWrapper>
            </Wrapper>
        </StyledRecipe>
    )
}
const StyledRecipe = styled.div`
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
  }
`

const StyledFeaturedItemBlock = styled.div`
  margin: ${mobileVw(-40)} auto 0;
  width: 100%;
  padding: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(260)};
    margin: 0 auto ${desktopVw(-90)};
    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }
  }
`

const SocialWrapper = styled.div`
  margin-left: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-left: 0;
  }
`

const BlockWrapper = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    padding: 0 ${desktopVw(125)};
  }
`

const InstagramBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${mobileVw(10)};
  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: column;
    min-width: ${desktopVw(370)};
    margin-top: 0;
  }
`

const InstagramTitleWrapper = styled.div`
  margin: 0 auto;

  span {
    font-size: ${mobileVw(18)};
    font-family: ${fonts.splandor};
    &:last-of-type {
      color: ${colors.mailleGold};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVw(200)};
    span {
      font-size: ${desktopVw(40)};
    }
  }
`

const InstagramIcon = styled(Image)`
  width: ${mobileVw(50)};
  margin: 0 ${mobileVw(10)} ${mobileVw(10)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(50)};
    margin: 0 auto ${desktopVw(10)};
  }
`

const Hero = styled.section`
  padding: ${mobileVw(14)} 0 0;
  background-color: ${colors.black};

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
`

const MobileWrapper = styled.div`
  .price,
  .gold-label {
    display: none;
  }

  .imageContainer {
    width: ${(props) => (props.resize ? `${props.resize}` : '100%')};
    img {
      object-fit: contain !important;
    }
  }

  h2 {
    color: ${colors.mailleGold};
  }

  .rating {
    margin-bottom: ${mobileVw(10)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const Breadcrumbs = styled.div`
  padding: ${mobileVw(11)} 0 0 ${mobileVw(16)};
  color: ${colors.white};
  background-color: ${colors.black};
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(14)};
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(16)} ${desktopVw(50)};
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(16)};
  }

  span: last-child {
    color: ${colors.mailleGold};
  }
`

const Container = styled.div`
  background-color: ${colors.black};

  @media (min-width: ${desktopBreakpoint}) {
    padding-left: ${desktopVw(120)};
    padding-right: ${desktopVw(240)};
  }
`

const TextWrapper = styled.header`
  background-color: ${colors.black};

  p {
    margin-bottom: 0;
    padding-bottom: ${mobileVw(16)};
  }
  padding: 0 ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;

    p {
      padding-bottom: ${desktopVw(16)};
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  h4 {
    letter-spacing: ${letterSpacing(100)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin: 0;
  }
`

const Main = styled.section`
  max-width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    margin-top: ${desktopVw(-85)};
  }
`

const RelatedProductsSidebar = styled.div`
  display: none;

  .imageContainer {
    padding: 0;
    img {
      padding: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: column;
    h2 {
      overflow: hidden;
      max-height: ${desktopVw(115)};
    }
  }
`

const RecipeInstructions = styled.div`
  max-width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${(props) => props.france ? `${desktopVw(858)}` : '100%'};
  }
`

const Description = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(18)};
  color: ${colors.white};
  margin: 0;

  p {
    padding: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(16)};
  }
`

const Title = styled.h1`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(35)};
  padding-top: ${mobileVw(18)};
  margin: 0 0 ${mobileVw(4)};
  color: ${colors.white};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: ${desktopVw(58)};
    margin: 0 0 ${desktopVw(18)};
    max-width: initial;
    padding-top: 0;
  }
`

const TopDecorationWrapper = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const BottomDecorationWrapper = styled.div`
  width: 100%;
  z-index: -999;
`

const AddToWishlist = styled.button`
  cursor: pointer;
  position: absolute;
  top: ${mobileVw(20)};
  right: ${mobileVw(20)};
  z-index: 10;
  padding: 0;
  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(24)};
    right: ${desktopVw(24)};
  }
`

const StyledHeart = styled(Heart)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(22)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32)};
    height: auto;
  }
`

const StyledHeartFilled = styled(HeartFilled)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(22)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32)};
    height: auto;
  }
`

export const RecipeQuery = graphql`
  query RecipeQuery($id: String!, $node_locale: String!) {
    contentfulPageRecipe(id: { eq: $id }) {
      node_locale
      title
      id
      seoTitle
      seoDescription
      images {
        id
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      thumbnailImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        raw
      }
      ingredients {
        raw
        references {
            ...richtextReferences
          }
      }
      preparation {
        raw
        references {
            ...richtextReferences               
        }
      }
      numberOfServings
      preparationTime
      inspirationTitle
      relatedProducts {
        shopifyProduct {
          id
          handle
          variants {
            edges {
              node {
                id
                title
                price{
                    amount
                }
              }
            }
          }
        }
        images {
          title
          description
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        slug
        title
        resizeImage
        thumbnailImage {
          title
          description
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      allergyLabels
      difficultyLevel
      seasonText
      dietaryTags
      courseTag
      inspirationTitle
      inspirationText
      slug
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      facebookLink
      instagramLink
      instagramIcon {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      classicProductPageTestimonialDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
          description
        }
      }
      breadcrumbsRecipePages {
        title
        link {
          ...linkTarget
        }
      }
      recipeInstagramImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      recipeInstagramImageDesktop {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      recipePageImageBlock1 {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      recipePageImageBlock2 {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      recipeInstagramTitle
      recipeInstagramText
      recipePreparationTitle
      recipeIngredientsTitle
      recipeRelatedProductsTitle
      decorationRecipePageBottomMobile {
        name
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      decorationRecipePageBottomDesktop {
        name
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      decorationTipSectionRecipePage {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
    }
  }
`
export default Recipe
